var exports = {};

exports = function (hljs) {
  var IDENT_RE = "[a-zA-Z-][a-zA-Z0-9_-]*";
  var RULE = {
    begin: /(?:[A-Z\_\.\-]+|--[a-zA-Z0-9_-]+)\s*:/,
    returnBegin: true,
    end: ";",
    endsWithParent: true,
    contains: [{
      className: "attribute",
      begin: /\S/,
      end: ":",
      excludeEnd: true,
      starts: {
        endsWithParent: true,
        excludeEnd: true,
        contains: [{
          begin: /[\w-]+\(/,
          returnBegin: true,
          contains: [{
            className: "built_in",
            begin: /[\w-]+/
          }, {
            begin: /\(/,
            end: /\)/,
            contains: [hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE]
          }]
        }, hljs.CSS_NUMBER_MODE, hljs.QUOTE_STRING_MODE, hljs.APOS_STRING_MODE, hljs.C_BLOCK_COMMENT_MODE, {
          className: "number",
          begin: "#[0-9A-Fa-f]+"
        }, {
          className: "meta",
          begin: "!important"
        }]
      }
    }]
  };
  return {
    case_insensitive: true,
    illegal: /[=\/|'\$]/,
    contains: [hljs.C_BLOCK_COMMENT_MODE, {
      className: "selector-id",
      begin: /#[A-Za-z0-9_-]+/
    }, {
      className: "selector-class",
      begin: /\.[A-Za-z0-9_-]+/
    }, {
      className: "selector-attr",
      begin: /\[/,
      end: /\]/,
      illegal: "$"
    }, {
      className: "selector-pseudo",
      begin: /:(:)?[a-zA-Z0-9\_\-\+\(\)"'.]+/
    }, {
      begin: "@(font-face|page)",
      lexemes: "[a-z-]+",
      keywords: "font-face page"
    }, {
      begin: "@",
      end: "[{;]",
      // at_rule eating first "{" is a good thing
      // because it doesn’t let it to be parsed as
      // a rule set but instead drops parser into
      // the default mode which is how it should be.
      illegal: /:/,
      // break on Less variables @var: ...
      contains: [{
        className: "keyword",
        begin: /\w+/
      }, {
        begin: /\s/,
        endsWithParent: true,
        excludeEnd: true,
        relevance: 0,
        contains: [hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, hljs.CSS_NUMBER_MODE]
      }]
    }, {
      className: "selector-tag",
      begin: IDENT_RE,
      relevance: 0
    }, {
      begin: "{",
      end: "}",
      illegal: /\S/,
      contains: [hljs.C_BLOCK_COMMENT_MODE, RULE]
    }]
  };
};

export default exports;